import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeSixComponent } from './components/home-six/home-six.component';

const routes: Routes = [
    {path: '', component: HomeSixComponent},
    // Here add new pages component

    {path: '**', component: HomeSixComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],    exports: [RouterModule]
})
export class AppRoutingModule { }