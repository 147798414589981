<section id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'services.title' | translate }} <span>{{ 'services.title-green' | translate }}</span></h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-female"></i>
                    </div>
                    <h3>{{ 'services.item-1-title' | translate }} </h3>
                    <p>{{ 'services.item-1-text' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-utensils"></i>
                    </div>
                    <h3>{{ 'services.item-2-title' | translate }} </h3>
                    <p>{{ 'services.item-2-text' | translate }} </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-clinic-medical"></i>
                    </div>
                    <h3>{{ 'services.item-3-title' | translate }}</h3>
                    <p>{{ 'services.item-3-text' | translate }}
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-child"></i>
                    </div>
                    <h3>{{ 'services.item-4-title' | translate }}</h3>
                    <p>{{ 'services.item-4-text' | translate }} </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-running"></i>
                    </div>
                    <h3>{{ 'services.item-5-title' | translate }}</h3>
                    <p>{{ 'services.item-5-text' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-heart"></i>
                    </div>
                    <h3>{{ 'services.item-6-title' | translate }}</h3>
                    <p>{{ 'services.item-6-text' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-spray-can"></i>
                    </div>
                    <h3>{{ 'services.item-7-title' | translate }}</h3>
                    <p>{{ 'services.item-7-text' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-cookie"></i>
                    </div>
                    <h3>{{ 'services.item-8-title' | translate }} </h3>
                    <p>{{ 'services.item-8-text' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-percent"></i>
                    </div>
                    <h3>{{ 'services.item-9-title' | translate }}</h3>
                    <p>{{ 'services.item-9-text' | translate }} </p>
                </div>
            </div>
        </div>
    </div>
</section>