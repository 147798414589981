<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>{{ 'testimonials.item-1' | translate }}</p>
                        <div class="info">
                            <h3>Γιώργος Π.</h3>
                            <span>Πελάτης</span>
                            <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>{{ 'testimonials.item-2' | translate }}</p>
                        <div class="info">
                            <h3>Άννα Κ.</h3>
                            <span>Πελάτης</span>
                            <img src="assets/img/user2.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>{{ 'testimonials.item-3' | translate }}</p>
                        <div class="info">
                            <h3>Γιάννης Π.</h3>
                            <span>Συνεργάτης</span>
                            <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>