<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2><span>{{ 'contact.title' | translate }}</span></h2>
            <p>{{ 'contact.text' | translate }}</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>{{ 'contact.name' | translate }}</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text"
                                        #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Πείτε μας το όνομα σας.</div>
                                        <div *ngIf="name.errors.minlength">Το όνομα πρέπει να είναι τουλάχιστον {{
                                            name.errors.minlength.requiredLength }} χαρακτήρες.</div>
                                        <div *ngIf="name.errors.maxlength">Το όνομα πρέπει να είναι μικρότερο από 50
                                            χαρακτήρες.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel"
                                        class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Η διεύθυνση
                                        email είναι υποχρεωτική.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>{{ 'contact.subject' | translate }}</label>
                                    <input required ngModel name="subject" type="text" #subject="ngModel"
                                        class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Πρέπει να
                                        ορίσετε ένα θέμα.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>{{ 'contact.tel' | translate }}</label>
                                    <input required ngModel name="number" type="text" #number="ngModel"
                                        class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Ο αριθμός
                                        σας είναι υποχρεωτικός.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>{{ 'contact.message' | translate }}</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30"
                                        rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Το μήνυμα
                                        πρέπει να γράφει κάτι...</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">{{
                                    'contact.send_btn' | translate }}</button>
                            </div>
                        </div>
                        <ngx-invisible-recaptcha #captchaElem [siteKey]="site_key" [useGlobalDomain]="false"
                            (success)="handleSuccess($event)" [ngModel]="recaptcha"
                            [ngModelOptions]="{ standalone: true }">
                        </ngx-invisible-recaptcha>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>{{ 'contact.location' | translate
                                        }}</span>{{ 'contact.location-text' | translate }}</li>
                                <li><i class="far fa-envelope"></i> <a
                                        href="mailto:info@lydaki.gr"><span>Email:</span>info@lydaki.gr</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a
                                        href="tel:2810223955"><span>Τηλέφωνο:</span>2810-223955</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>