<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>{{ 'welcome.subtitle' | translate }}</h4>
            <h2>{{ 'welcome.title' | translate }}<span>!</span></h2>
            <p>{{ 'welcome.description' | translate }}</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-user-friends"></i>
                    </div>
                    <h3>{{ 'welcome.item-1-title' | translate }}</h3>
                    <p>{{ 'welcome.item-1-text' | translate }}</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-graduation-cap"></i>
                    </div>
                    <h3>{{ 'welcome.item-2-title' | translate }}</h3>
                    <p>{{ 'welcome.item-2-text' | translate }}</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-cubes"></i>
                    </div>
                    <h3>{{ 'welcome.item-3-title' | translate }}</h3>
                    <p>{{ 'welcome.item-3-text' | translate }}</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>