<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'about.title' | translate }} <span>{{ 'about.title-green-text' | translate }}</span></h2>
            <p>{{ 'about.description' | translate }}</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>{{ 'about.item-1-title' | translate }} <span>{{ 'about.item-1-green-text' | translate }}</span></h2>
                        <p>{{ 'about.item-1-text' | translate }}</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>{{ 'about.check-1' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'about.check-2' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'about.check-3' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'about.check-4' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'about.check-5' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'about.check-6' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/video/thumbnail.png" alt="about">
                    <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="assets/video/lamberts.mp4" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>