<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'who-we-are.title' | translate }} <span>{{ 'who-we-are.green' | translate }}</span></h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-user-md"></i>
                    <h3>{{ 'who-we-are.item-1-title' | translate }}</h3>
                    <p>{{ 'who-we-are.item-1-text' | translate }}</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-child"></i>
                    <h3>{{ 'who-we-are.item-2-title' | translate }}</h3>
                    <p>{{ 'who-we-are.item-2-text' | translate }}</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-bullseye"></i>
                    <h3>{{ 'who-we-are.item-3-title' | translate }}</h3>
                    <p>{{ 'who-we-are.item-3-text' | translate }}</p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-book-open"></i>
                    <h3>{{ 'who-we-are.item-4-title' | translate }}</h3>
                    <p>{{ 'who-we-are.item-4-text' | translate }}</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-award"></i>
                    <h3>{{ 'who-we-are.item-5-title' | translate }}</h3>
                    <p>{{ 'who-we-are.item-5-text' | translate }}</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>{{ 'who-we-are.item-6-title' | translate }}</h3>
                    <p>{{ 'who-we-are.item-6-text' | translate }}</p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>