import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    isEnglish: boolean = false;
    other_lang:string = 'en';
    flag_path: string = 'assets/img/english.png';
    show_espa = true;

    constructor(private viewportScroller: ViewportScroller, private translate: TranslateService, public ngxSmartModalService: NgxSmartModalService) {
        translate.setDefaultLang('gr');
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    useLanguage(language: string): void {
        if (language == 'gr') {
            this.other_lang = 'en';
            this.flag_path = "assets/img/english.png";
        }
        else {
            this.other_lang = 'gr';
            this.flag_path = "assets/img/greece.png"
        }
        this.translate.use(language);
    }

    ngOnInit() {
        window.addEventListener("scroll", this.scroll, true); 
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    goToLink(url: string) {
        window.open(url, "_blank");
    }

    scroll = () => {
        const scrollPos = document.documentElement.scrollTop;

        // change '5' according to when you want to change the image
        if (scrollPos > 4) {
            this.show_espa = false;

        }
        else {
            this.show_espa = true;

        }

    };

    espa_click(){
    }
}