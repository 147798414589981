<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav
    stickyClass="sticky-box-shadow" ngStickyNav>
    <img *ngIf="show_espa" class="espa" (click)="ngxSmartModalService.getModal('myModal').open()"
        src="assets/img/espa.png" width="290" height="50" alt="">
    <div class="container">
        <a class="navbar-brand" href="#">
            <img src="assets/img/logo.png" width="130" height="130" alt="">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">{{ 'nav.home' | translate
                        }}</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">{{ 'nav.about' | translate
                        }}</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">{{ 'nav.contact' | translate
                        }}</span></li>
                <li class="nav-item"><span class="nav-link"
                        (click)="goToLink('https://www.pharmaplaza.gr/')">E-shop</span></li>
                <li class="nav-item lang-item"><img src="{{flag_path}}" (click)="useLanguage(other_lang)" width="30"
                        height="30" alt="" srcset=""></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">{{ 'nav.home' |
                        translate }}</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">{{ 'nav.about' |
                        translate }}</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">{{ 'nav.contact'
                        | translate }}</span></li>
                <li class="nav-item"><span class="nav-link"
                        (click)="goToLink('https://www.pharmaplaza.gr/')">E-shop</span></li>
                <li class="nav-item lang-item-mobile"><img src="{{flag_path}}" (click)="useLanguage(other_lang)"
                        width="30" height="30" alt="" srcset=""></li>
            </ul>
        </div>
    </div>
</nav>

<ngx-smart-modal customClass="msg-modal" #myModal identifier="myModal">
    <div class="wrapper" style="display: flex; justify-content: center;">
        <img class="espa-modal" src="assets/img/espa_modal.jpg" alt="espa" srcset="">
    </div>
</ngx-smart-modal>