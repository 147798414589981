
<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/">Lydaki</a><span>.gr</span></h3>
        <div class="img-fluid">
        <div class="FixImage">
            <a href="https://playcloudservices.com/" target="_blank"><img src="assets/img/secure-300x91.png" alt="imagePlayCloud" ></a>
            </div>
        </div>
        <ul>
            <li><a href="https://el-gr.facebook.com/farmakiolidaki" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="https://el-gr.facebook.com/farmakiolidaki"  target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="https://el-gr.facebook.com/farmakiolidaki"  target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="https://el-gr.facebook.com/farmakiolidaki"  target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="https://el-gr.facebook.com/farmakiolidaki"  target="_blank" class="fab fa-skype"></a></li>
        </ul>
        
        <p (click)="goToLink('https://playsystems.io')">Playsystems.io <i class="far fa-copyright"></i>2021-2023 All Rights Reserved.</p>
    </div>
</footer>


<ngx-scrolltop></ngx-scrolltop>
