<app-navbar></app-navbar>
<div class="main-banner item-bg-three">
    <div class="creative-banner-two"></div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>{{ 'landing.title' | translate }}</h4>
                    <h1>{{ 'landing.name' | translate }} <span>{{ 'landing.green' | translate }}</span></h1>
                    <p>{{ 'landing.address' | translate }}</p>
                    <a (click)="onClick('contact')" class="btn btn-primary">{{ 'landing.contact_btn' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-who-we-are></app-who-we-are>

<app-services-two></app-services-two>

<app-funfacts></app-funfacts>

<!--<app-team></app-team> !-->

<!--<app-why-we-different></app-why-we-different> !-->

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-partner></app-partner>

<app-contact></app-contact>
<app-footer></app-footer>