<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Meet Addax</h4>
            <h2>Our Creative <span>Team</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">John Smith</h3>
                            <span class="post">CEO</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">David Warner</h3>
                            <span class="post">Web Developer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">James Andy</h3>
                            <span class="post">Back-End Developer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-4.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">David Smith</h3>
                            <span class="post">Front-End Developer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-5.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">John Doe</h3>
                            <span class="post">Web Developer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-6.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Olivia Smith</h3>
                            <span class="post">Photographer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>