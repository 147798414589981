import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ContactServiceService } from 'src/app/ContactService';
import { contactItem } from 'src/app/contactItem';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    constructor(public toastService: ToastrService, private contact_service: ContactServiceService, private viewportScroller: ViewportScroller, private reCaptchaV3Service: ReCaptchaV3Service
    ) { }

    new_item: contactItem;
    post_observable: Observable<JSON>;

    captcha_complete: boolean = false;
    public recaptcha;
    public site_key: string = "6LfN8AMcAAAAANCjemDfZKN_jIYw1HvU54PmU7d7";
    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.reCaptchaV3Service.execute(this.site_key, 'homepage', (token) => {
            console.log('This is your token: ', token);
        }, {
            useGlobalDomain: false
        });
    }

    submit(form) {
        if (this.captcha_complete) {
            this.new_item.Name = form.name;
            this.new_item.Email = form.email;
            this.new_item.key = "gzTN2KPiVSHnSbTeuyWFA45MFxgnGsk1PTr20lCvjNNP330HGDXjkNs0yZ8j";
            this.new_item.Subject = form.subject;
            this.new_item.telephone = form.number;

            this.post_observable = this.contact_service.addMessage(this.new_item);
            this.post_observable.subscribe({
                next: (data) => {
                    console.log(data);
                },
            });
            this.toastService.success("Message sent succesfully");
        }
        else {
            this.toastService.error('Captcha error');
        }
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var subject = form.subject;
        console.log(subject);

        var message = form.message;
        console.log(message);
    }

    handleSuccess($event) {
        this.captcha_complete = true;
    }

}