import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { StickyNavModule } from "ng2-sticky-nav";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { NgxScrollTopModule } from "ngx-scrolltop";
import { CarouselModule } from "ngx-owl-carousel-o";
import { FormsModule } from "@angular/forms";
import { CountUpModule } from "ngx-countup";
import { NgxTypedJsModule } from "ngx-typed-js";
import { AccordionModule } from "ngx-accordion";
import { TabsModule } from "ngx-tabset";
import { ParticlesModule } from "ngx-particle";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";
import { NavbarComponent } from "./components/common/navbar/navbar.component";
import { WelcomeComponent } from "./components/common/welcome/welcome.component";
import { AboutComponent } from "./components/common/about/about.component";
import { WhoWeAreComponent } from "./components/common/who-we-are/who-we-are.component";
import { FunfactsComponent } from "./components/common/funfacts/funfacts.component";
import { TeamComponent } from "./components/common/team/team.component";
import { FooterComponent } from "./components/common/footer/footer.component";
import { PartnerComponent } from "./components/common/partner/partner.component";
import { FeedbackComponent } from "./components/common/feedback/feedback.component";
import { FaqComponent } from "./components/common/faq/faq.component";
import { WhyWeDifferentComponent } from "./components/common/why-we-different/why-we-different.component";
import { ContactComponent } from "./components/common/contact/contact.component";
import { HomeSixComponent } from "./components/home-six/home-six.component";
import { ServicesTwoComponent } from "./components/common/services-two/services-two.component";
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "ngx-captcha";
import { ToastrModule } from "ngx-toastr";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        WelcomeComponent,
        AboutComponent,
        WhoWeAreComponent,
        FunfactsComponent,
        TeamComponent,
        FooterComponent,
        PartnerComponent,
        FeedbackComponent,
        FaqComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        ServicesTwoComponent,
        HomeSixComponent,
    ],
    imports: [
        ToastrModule.forRoot(), // ToastrModule added
        ReactiveFormsModule,
        NgxCaptchaModule,
        BrowserModule,
        AppRoutingModule,
        StickyNavModule,
        BrowserAnimationsModule,
        NgxSmartModalModule.forRoot(),
        NgxScrollTopModule,
        CarouselModule,
        FormsModule,
        AccordionModule,
        CountUpModule,
        TabsModule,
        NgxTypedJsModule,
        ParticlesModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
