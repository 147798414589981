<div class="partner-area ptb-70 bg-fffdfd">
    <div class="container">
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-2.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-3.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                       <img src="assets/img/partner-4.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                       <img src="assets/img/partner-5.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-6.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                      <img src="assets/img/partner-7.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-8.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-9.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                       <img src="assets/img/partner-10.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                       <img src="assets/img/partner-11.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                       <img src="assets/img/partner-12.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                       <img src="assets/img/partner-13.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                     <img src="assets/img/partner-14.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                       <img src="assets/img/partner-15.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-16.jpeg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                      <img src="assets/img/partner-17.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-18.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-19.png" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                       <img src="assets/img/partner-20.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                     <img src="assets/img/partner-21.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-22.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                       <img src="assets/img/partner-23.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-24.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets/img/partner-25.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                      <img src="assets/img/partner-26.jpg" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                      <img src="assets/img/partner-27.png" alt="partner">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>