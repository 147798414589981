<div class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'faq.title' | translate }} <span>{{ 'faq.title-green' | translate }}</span></h2>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <accordion>
                        <accordion-group heading="{{ 'faq.item-1-title' | translate }}">
                            <p>{{ 'faq.item-1-text' | translate }}</p>
                        </accordion-group>
                        <accordion-group heading="{{ 'faq.item-2-title' | translate }}">
                            <p>{{ 'faq.item-2-text' | translate }}</p>
                        </accordion-group>
                        <accordion-group heading="{{ 'faq.item-3-title' | translate }}">
                            <p>{{ 'faq.item-3-text' | translate }}</p>
                        </accordion-group>
                        <accordion-group heading="{{ 'faq.item-4-title' | translate }}">
                            <p>{{ 'faq.item-4-text' | translate }}</p>
                        </accordion-group>
                        <accordion-group heading="{{ 'faq.item-5-title' | translate }}">
                            <p>{{ 'faq.item-5-text' | translate }}</p>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/logo.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>