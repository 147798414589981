<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="far fa-smile"></i>
                    <h3><span [countUp]="10000">00</span></h3>
                    <p>{{ 'fun-facts.item-1' | translate }} </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-prescription-bottle"></i>
                    <h3><span [countUp]="19000">00</span></h3>
                    <p>{{ 'fun-facts.item-2' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-calendar-check"></i>
                    <h3><span [countUp]="26">00</span></h3>
                    <p>{{ 'fun-facts.item-3' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-user"></i>
                    <h3><span [countUp]="8">00</span></h3>
                    <p>{{ 'fun-facts.item-4' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>